/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import enterprise from "../assets/enterprise.webp";

const EnterpriseSection = () => {
  const { t, i18n } = useTranslation("enterprise");
  const imageWidth = 500;
  const imageHeight = 500;

  return (
    <section className="container mx-auto px-4 sm:p-6 lg:px-12 relative">
      <div className={`flex flex-wrap items-start justify-center lg:${i18n.language === "en" ? "justify-start" : "justify-end"} lg:mb-12`}>
        <div className="w-full lg:w-1/2">
          <h2 className="text-3xl lg:text-5xl font-bold mb-8">{t('Enterprise')}</h2>
          <p className="text-base lg:text-lg mb-3">{t('We provide enterprises with the tools and resources they need to succeed in their business:')}</p>
          <ul className="list-none">
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t('Streamlined Operations')}:</span> {t('Optimize your print, packaging, and corporate gifting needs with a centralized platform.')}
              </div>
            </li>
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t('Scalable Solutions')}:</span> {t('Humd adapts to your business size, offering solutions for bulk orders and complex requirements.')}
              </div>
            </li>
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t('Brand Consistency')}:</span> {t('Maintain brand identity across all printed materials and corporate gifts with easy design tools.')}
              </div>
            </li>
            <li className="flex items-start ">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t('Data-Driven Insights')}:</span> {t('Gain valuable insights into your ordering habits to optimize costs and improve efficiency.')}
              </div>
            </li>
          </ul>
          <p className="py-4 ml-3">
            {t('Click here to discover how')} <span className="text-green-600">HUMD</span> {t('can support your enterprise business.')}
          </p>
          <Link to="/enterprise">
            <button className="flex px-8 py-2 mt-4 ml-2 text-[#ED636E] text-[20px] border border-red-500 rounded-[20px] hover:bg-red-500 hover:text-white font-semibold shadow-lg box-border">
              {t('Explore')}
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 mt-2 mx-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </Link>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex justify-start mx-auto lg:justify-end lg:absolute lg:top-10 lg:right-0 lg:-mr-12">
        <div className="w-full lg:w-full px-4 lg:px-0 my-10 ">
          <img src={enterprise} alt={t('Enterprise image')} width={imageWidth} height={imageHeight} layout="responsive" objectFit="contain" />
        </div>
      </div>
    </section>
  );
};

export default EnterpriseSection;
