/* eslint-disable jsx-a11y/img-redundant-alt */
import {Link} from "react-router-dom";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import reseller from "../assets/vendor-main-section-img.webp";
import {useTranslation} from "react-i18next";

const VendorsSection = () => {
  const {t, i18n} = useTranslation("vendors");
  const imageWidth = 500;
  const imageHeight = 500;

  return (
    <section className="container mx-auto px-6 sm:p-6 md:p-24 lg:px-12 relative lg:pt-12 lg:pb-4">
      <div className={`flex flex-wrap items-start justify-center lg:justify-${i18n.language === "en" ? "start" : "end"} lg:mb-12`}>
        <div className="w-full lg:w-1/2">
          <h2 className="text-3xl lg:text-5xl font-bold mb-8">{t("Vendors")}</h2>
          <p className="text-base lg:text-lg mb-3">{t("For our vendors, we offer innovative tools and opportunities to maximize their potential:")}</p>
          <ul className="list-none">
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Digital Payment Solution:")}</span> {t("Our cutting-edge digital payment solution streamlines transactions, offering you a secure and convenient way to make payments. With seamless integration into your existing systems, our solution ensures swift and hassle-free transactions, enhancing your overall payment experience.")}
              </div>
            </li>
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Operation Dashboard:")}</span> {t("Our operation dashboard provides real-time insights and analytics, empowering you with valuable data to optimize your business operations. With customizable features and intuitive design, our dashboard offers a comprehensive overview of key metrics and performance indicators, allowing you to make informed decisions and drive success.")}
              </div>
            </li>
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Largest Market Access:")}</span> {t("Vendors gain access to our extensive marketplace, opening new sales channels and expanding their reach.")}
              </div>
            </li>
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Design Tools:")}</span> {t("Our advanced design tools enable vendors to create custom products that meet market demands.")}
              </div>
            </li>
            <li className="flex items-start">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Cost Calculator:")}</span> {t("Our cost calculator ensures vendors accurately price their products to remain competitive while maximizing profits.")}
              </div>
            </li>
          </ul>
          <p className="py-4 ml-3">
            {t("Click here to see how")} <span className="text-green-600">{t("HUMD")}</span> {t("can help your business grow.")}
          </p>
          <Link to="/vendors">
            <button className="flex px-8 py-2 mb-6 ml-2 text-[#ED636E] text-[20px] border border-red-500 rounded-[20px] hover:bg-red-500 hover:text-white font-semibold shadow-lg box-border">
              {t("Explore")}
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 mt-2 mx-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </Link>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex justify-start mx-auto lg:justify-end lg:absolute lg:top-10 lg:right-0 lg:-mr-12">
        <div className="w-full lg:w-full lg:mt-36 my-10 mx-4">
          <img src={reseller} alt={t("reseller")} width={imageWidth} height={imageHeight} layout="responsive" objectFit="contain" />
        </div>
      </div>
    </section>
  );
};

export default VendorsSection;
