import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

const Culture = () => {
  const { t } = useTranslation("culturecomponent");

  return (
    <div>
      <section className="body-font mb-12">
        <div className="container lg:px-0 px-8 lg:py-10 py-6 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-4xl font-bold mb-6">{t("Our Culture")}</h2>
            <p className="lg:w-1/2 mx-auto leading-relaxed text-base mb-12 text-left">
              {t("Welcome to HUMD, the GCC's premier printing marketplace for customizing and printing online. From business cards to t-shirts and corporate gifts, we offer a comprehensive range of printing solutions to meet your needs. Our commitment to fast, affordable, and free delivery ensures a seamless experience for our valued customers.")}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full px-8 lg:px-0">
          <h2 className="text-4xl font-bold mb-6 text-center lg:w-1/2 lg:mx-auto mx-4">{t("Explore Beyond the Print Shop: Join the HUMD Community")}</h2>
          <p className="lg:w-1/2 mx-auto leading-relaxed text-base text-left px-4 mb-10">
            {t("HUMD is more than just a printing platform – it's a community of passionate individuals and businesses who believe in the power of self-expression.")}
          </p>

          <div className="w-full lg:w-1/2 mx-auto">
            <ul className="list-none">
              <li className="flex items-start mb-2">
                <div className="min-w-[24px]">
                  <CheckCircleIcon className="w-6 h-6 text-green-500" />
                </div>
                <div className="flex-1 ml-2">
                  <span className="font-bold">{t("Follow us on Social Media:")}</span> {t("Stay up-to-date on the latest trends, design tips, and exclusive offers by following us on social media. (Insert links to social media pages)")}
                </div>
              </li>
              <li className="flex items-start mb-2">
                <div className="min-w-[24px]">
                  <CheckCircleIcon className="w-6 h-6 text-green-500" />
                </div>
                <div className="flex-1 ml-2">
                  <span className="font-bold">{t("Share Your Creations:")}</span> {t("Inspire others by sharing your custom HUMD creations using our hashtag #HUMDYourStyle.")}
                </div>
              </li>
            </ul>
            <p className="mx-auto leading-relaxed text-base text-left px-4 my-10">
              {t("Keep checking this page for exciting developments at HUMD. Thank you for choosing us as your trusted printing partner.")}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Culture;
