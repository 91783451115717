import React from "react";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import Newsletter from "../compnents/newsletter";

const Sustainable = () => {
  const { t } = useTranslation("sustainablecomponent");
  return (
    <div>
      <section className="body-font mb-6">
        <div className="container lg:py-10 py-8 mx-auto px-4">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-4xl font-bold mb-6">{t("Sustainable Printing at HUMD")}</h2>
            <h3 className="text-3xl font-bold mb-6 text-gray-700">{t("A Sustainable Process")}</h3>
            <p className="lg:w-1/2 mx-auto leading-relaxed text-base mb-6 text-left px-4 lg:px-0">
              <span className="font-bold">{t("At HUMD, we're revolutionizing printing with a focus on sustainability.")}</span> {t("We believe style shouldn't come at the expense of the environment. That's why we partner with best-in-class eco-friendly manufacturers to create clothing and accessories that are:")}
            </p>
          </div>
          <div className="w-full lg:w-1/2 mx-auto">
            <ul className="list-none">
              {[
                {
                  label: "Sustainable",
                  text: "Biodegradable fabrics, non-toxic inks, and responsible production practices minimize our environmental impact."
                },
                {
                  label: "Stylish",
                  text: "Look good and feel good knowing your outfit aligns with your values."
                },
                {
                  label: "Economical",
                  text: "Sustainable printing shouldn't break the bank. We offer a variety of options to fit your budget."
                }
              ].map((item, index) => (
                <li className="flex items-start mb-2" key={index}>
                  <div className="min-w-[24px]">
                    <CheckCircleIcon className="w-6 h-6 text-green-500" />
                  </div>
                  <div className="flex-1 ml-2">
                    <span className="font-bold">{t(item.label)}:</span> {t(item.text)}
                  </div>
                </li>
              ))}
            </ul>
            <p className="mx-auto leading-relaxed text-base text-left lg:px-0 px-2 mt-6">
              {t("Together, we can create a more sustainable future for printing. By choosing HUMD, you're not just getting stylish clothes, you're making a positive impact on the planet. It's a win-win for you and the environment.")}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <h3 className="text-3xl font-bold mb-6 text-gray-700 text-center">{t("Freedom of Choice")}</h3>
          <p className="lg:w-1/2 mx-auto leading-relaxed text-base text-left lg:px-4 px-6 mb-10">
            {t("Pick and choose from our wide range of materials and fabrics that best suit your preferences. Our dedicated designers are here to assist you in creating and perfecting your designs before printing.")}
          </p>
        </div>
        <div className="flex flex-col w-full">
          <h3 className="text-3xl font-bold mb-6 text-gray-700 text-center">{t("Transparency You Can Trust")}</h3>
          <p className="lg:w-1/2 mx-auto leading-relaxed text-base text-left lg:px-4 px-6 mb-10">
            {t("At HUMD, sustainability is more than a buzzword. We are committed to a transparent and responsible approach, ensuring the highest quality eco-friendly products. Every sustainable item in our catalog reflects this dedication to both quality and the environment.")}
            <br /> <br />
            {t("Our commitment to sustainability fosters lasting relationships with our customers:")}
          </p>
          <div className="w-full lg:w-1/2 mx-auto lg:px-4 px-6">
            <ul className="list-none">
              {[
                {
                  label: "Empowering Choices",
                  text: "We empower you to make eco-conscious decisions and inspire positive change."
                },
                {
                  label: "Greener Tomorrow",
                  text: "We build a community that shares our vision for a sustainable future."
                },
                {
                  label: "Quality & Performance",
                  text: "We provide eco-friendly products that meet your style needs and exceed expectations in terms of performance and durability."
                },
                {
                  label: "Informed Decisions",
                  text: "Our focus on transparency allows you to make informed choices, knowing our products are made with respect for the environment."
                }
              ].map((item, index) => (
                <li className="flex items-start mb-2" key={index}>
                  <div className="min-w-[24px]">
                    <CheckCircleIcon className="w-6 h-6 text-green-500" />
                  </div>
                  <div className="flex-1 ml-2">
                    <span className="font-bold">{t(item.label)}:</span> {t(item.text)}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex flex-col w-full py-8 px-4">
          <h3 className="text-3xl font-bold mb-6 text-gray-700 text-center">{t("By the Numbers")}</h3>
          <p className="lg:w-1/2 mx-auto leading-relaxed text-base text-left lg:px-4 px-6 mb-10">
            {t("HUMD is a thriving community dedicated to sustainable printing:")}
          </p>
          <div className="w-full lg:w-1/2 mx-auto lg:px-4 px-6">
            <ul className="list-none">
              {[
                {
                  label: "10,000+ Products Printed",
                  text: "We've helped create a vast collection of sustainable apparel and accessories."
                },
                {
                  label: "50+ Vendors",
                  text: "A network of eco-conscious vendors allows us to offer a diverse selection."
                },
                {
                  label: "200+ Companies",
                  text: "We've partnered with hundreds of companies who share our sustainability goals."
                }
              ].map((item, index) => (
                <li className="flex items-start mb-2" key={index}>
                  <div className="min-w-[24px]">
                    <CheckCircleIcon className="w-6 h-6 text-green-500" />
                  </div>
                  <div className="flex-1 ml-2">
                    <span className="font-bold">{t(item.label)}:</span> {t(item.text)}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <Newsletter />
    </div>
  );
};

export default Sustainable;
