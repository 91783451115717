import React from "react";
import {useTranslation} from "react-i18next";

const Blog = () => {
  const {t, i18n} = useTranslation("blog");
  const isArabic = i18n.language === "ar"; // Check if the language is Arabic

  return (
    <section class="text-gray-600 body-font py-24 text-center">
      <h2 className="text-3xl lg:text-5xl font-bold mb-8">{isArabic ? <>......{t("Coming Soon")}</> : <>{t("Coming Soon")}......</>}</h2>
    </section>
  );
};

export default Blog;
