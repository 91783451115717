import React from "react";
import { useTranslation } from "react-i18next";

const Returns = () => {
  const { t } = useTranslation("returns");

  return (
    <div>
      <section className="body-font mb-12 pt-4">
        <div className="container lg:px-5 px-8 lg:py-10 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-4xl font-bold mb-6">{t("HUMD Returns & Refunds Policy")}</h2>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-4 text-left">
              {t("At HUMD, we're passionate about providing you with the best possible printing experience. We take pride in the quality of our products and services, ensuring you receive exactly what you ordered. However, we understand that sometimes things might not go exactly according to plan.")}
              <br />
              {t("This policy outlines our easy return and refund process, designed to offer you peace of mind and a smooth resolution in the unlikely event you receive a damaged, incorrect, or unusable product. We're here to ensure your satisfaction, so let's navigate any potential issues together!")}
            </p>
          </div>
          <div className="flex flex-col text-center w-full">
            <h2 className="text-4xl font-bold mb-10">{t("Returns")}</h2>
            <div className="lg:w-1/2 lg:mx-auto text-2xl mb-6 text-left">
              <h2 className="text-gray-700 mb-4 text-2xl font-bold">{t("Eligible Returns")}</h2>
              <p className="text-xl mb-4 text-left font-medium">{t("You can return a product if it is:")}</p>
              <ol style={{ listStyleType: "disc" }} className="mx-6">
                <li className="leading-relaxed text-base mb-2">{t("Damaged")}</li>
                <li className="leading-relaxed text-base mb-2">{t("Incorrect (not the product you ordered)")}</li>
                <li className="leading-relaxed text-base mb-2">{t("Unfit for use")}</li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <h2 className="lg:w-1/2 lg:mx-auto leading-relaxed text-left text-2xl font-bold mb-4 text-gray-700">{t("Return Process")}</h2>
            <p className="lg:w-1/2 mx-auto leading-relaxed text-base mb-4 text-left">
              1. <span className="font-bold">{t("Report the Issue:")}</span> {t("Within 48 hours of receiving the product, email us with details of the received product(s) and supporting photographs.")}
            </p>
            <p className="lg:w-1/2 mx-auto leading-relaxed text-base mb-4 text-left">
              2. <span className="font-bold">{t("Verification:")}</span> {t("We will verify and validate your complaint based on the following conditions:")}
              <ol className="mx-8 text-left text-base" style={{ listStyleType: "disc" }}>
                <li className="leading-relaxed mb-2">{t("The customized product design does not match the submitted design.")}</li>
                <li className="leading-relaxed mb-2">{t("The product is not the one you selected.")}</li>
                <li className="leading-relaxed mb-2">{t("The product(s) have not been used.")}</li>
              </ol>
            </p>
            <p className="lg:w-1/2 mx-auto leading-relaxed text-base mb-4 text-left">
              3. <span className="font-bold">{t("Return Initiation:")}</span> {t("If your complaint is valid, we will initiate the return process and arrange for pick-up of the damaged product through our logistics partner.")}
            </p>
            <p className="lg:w-1/2 mx-auto leading-relaxed text-base mb-4 text-left">
              4. <span className="font-bold">{t("Return Shipping:")}</span> {t("If pick-up is not possible, we will notify you to return the product via courier service. Courier charges up to AED 15 per shipment/order may be reimbursed upon prior consultation with HUMD.")}
            </p>
            <p className="lg:w-1/2 mx-auto leading-relaxed text-base mb-4 text-left">
              5. <span className="font-bold">{t("Product Replacement:")}</span> {t("Upon receipt of the damaged/defective product, we will replace it with the correct product as per your specifications and deliver it as soon as possible.")}
            </p>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-1/2 mx-auto">
              <h2 className="mx-auto text-2xl font-bold mb-6 text-gray-700">{t("Important Notes")}</h2>
              <ol className="text-xl mb-4 text-left mx-6" style={{ listStyleType: "disc" }}>
                <li className="leading-relaxed text-base mb-2">{t("Products must be reported damaged/defective within 48 hours of receipt. We will not be responsible for replacements outside this window.")}</li>
                <li className="leading-relaxed text-base mb-2">{t("For bulk orders, only damaged items will be replaced, not the entire order.")}</li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col text-center w-full">
            <h2 className="text-4xl font-bold my-10">{t("Refunds")}</h2>
            <div className="lg:w-1/2 mx-auto text-2xl mb-6 text-left">
              <h2 className="text-gray-700 mb-4 text-2xl font-bold">{t("Eligible Refunds")}</h2>
              <p className="text-xl mb-4 text-left font-medium">{t("You may be eligible for a refund if:")}</p>
              <ol style={{ listStyleType: "disc" }} className="mx-6">
                <li className="leading-relaxed text-base mb-2">{t("The vendor is unable to fulfill your order.")}</li>
                <li className="leading-relaxed text-base mb-2">{t("You cancel the order before production begins (contact our Customer Support Team).")}</li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col text-center w-full">
            <div className="lg:w-1/2 mx-auto text-2xl mb-6 text-left">
              <h2 className="text-gray-700 mb-4 text-2xl font-bold">{t("Refund Processing")}</h2>
              <p className="mb-4 text-left text-base">{t("Approved refunds will be processed within 7 working days to your online account.")}</p>
            </div>
          </div>
          <div className="flex flex-col text-center w-full">
            <h2 className="text-4xl font-bold mb-10">{t("Cancellations")}</h2>
            <div className="lg:w-2/3 mx-auto text-2xl mb-6 text-left">
              <p className="text-base mb-4 text-left">{t("You can cancel your order before the production process begins. To cancel, email us with your order number and request a cancellation. We will process the cancellation and notify you within 2-3 business days.")}</p>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <h2 className="text-4xl font-bold mb-10 lg:text-center text-left">{t("Return and Refund Exclusions")}</h2>
            <div className="lg:w-2/3 lg:mx-auto text-2xl mb-6 text-left mx-6">
              <ul style={{ listStyleType: "disc" }}>
                <li className="leading-relaxed text-base mb-2">{t("Low-quality or low-resolution images or graphics submitted by you, resulting in defects not inherent to the product.")}</li>
                <li className="leading-relaxed text-base mb-2">{t("Grammatical, spelling, typographic, or punctuation errors in your design submission.")}</li>
                <li className="leading-relaxed text-base mb-2">{t("Uploading incorrect files.")}</li>
                <li className="leading-relaxed text-base mb-2">{t("Images/graphics not created in the specified format.")}</li>
                <li className="leading-relaxed text-base mb-2">{t("Errors in choosing product specifications (size, texture, quality).")}</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col text-center w-full">
            <h2 className="text-4xl font-bold mb-10">{t("General Policy")}</h2>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-4 text-left">
              {t("HUMD is committed to providing goods and services that conform to standard specifications, conditions, and controls related to consumer health and safety. In the event of a product defect or safety hazard, we will immediately recall the product and issue a public safety announcement.")}
              <br />
              {t("If a product malfunctions more than three times within the first year of ownership, negatively impacting its functionality, we will replace it free of charge with a new product of the same type and specifications. Alternatively, we may recover the product and issue a full refund.")}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Returns;
