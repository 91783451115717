"use client"; // This must be at the top of the file

import React, {useState, useEffect} from "react";
import emailjs from "emailjs-com";
import {useForm} from "react-hook-form";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useTranslation} from "react-i18next";

const ContactFormData = () => {
  const {t} = useTranslation("contactFormData");

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  const onSubmit = (data) => {
    emailjs
      .send(
        "service_69zvvr8", // Your EmailJS service ID
        "template_uckdd48", // Your EmailJS template ID
        {
          from_name: "humdsa", // From Name (Your site)
          name: data.name, // User's name from form
          email: data.email, // User's email from form
          phone: data.phone, // User's phone from form
          message: data.message, // User's message from form
        },
        "deR1g0v6RNtlfsjw_" // Your EmailJS public key
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setIsSubmitted(true);
        reset();
        toast.success(t("toast.success"));
      })
      .catch((err) => {
        console.log("FAILED...", err);
        toast.error(t("toast.error"));
      });
  };

  return (
    <>
      <div className="px-5 lg:py-12 py-8 mx-auto">
        <div className="flex flex-col text-center w-full ">
          <h1 className="lg:text-[45px] text-[25px] font-bold lg:w-2/3 w-full lg:px-12 mx-auto block">
            {t("Be part of our exciting")} <span className="text-green-600">{t("Saudi Arabia")}</span> {t("launch")}!
          </h1>
        </div>
        <div>
          <p className="lg:px-8 px-3 text-center w-full lg:w-2/3 mx-auto block py-4">
            {t("Sign up now with your email and phone number to claim your")} <span className="text-green-600 font-bold">{t("free 1-year")}</span> {t("subscription to a premium digital business card. Connect and grow effortlessly - don’t miss out!")}
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="lg:w-2/3 md:w-2/3 mx-auto lg:px-8">
          <div className="flex flex-wrap">
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                  {t("Name")}
                </label>
                <input type="text" id="name" {...register("name")} className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-[#2F9E5B] focus:bg-white focus:ring-2 focus:ring-[#2F9E5B] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
              </div>
            </div>

            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                  {t("Email*")}
                </label>
                <input type="email" id="email" {...register("email", {required: t("email_required")})} className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-[#2F9E5B] focus:bg-white focus:ring-2 focus:ring-[#2F9E5B] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
              </div>
            </div>

            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="phone" className="leading-7 text-sm text-gray-600">
                  {t("Phone Number*")}
                </label>
                <input type="tel" id="phone" {...register("phone", {required: t("phone_required")})} className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-[#2F9E5B] focus:bg-white focus:ring-2 focus:ring-[#2F9E5B] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone.message}</p>}
              </div>
            </div>

            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="message" className="leading-7 text-sm text-gray-600">
                  {t("Message")}
                </label>
                <textarea id="message" {...register("message")} className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-[#2F9E5B] focus:bg-white focus:ring-2 focus:ring-[#2F9E5B] h-20 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
              </div>
            </div>

            <div className="p-2 w-full">
              <button type="submit" className="mx-auto block border border-transparent bg-[#2F9E5B] py-2 px-6 focus:outline-none rounded text-white transition duration-300 ease-in-out text-base">
                {t("Submit")}
              </button>
            </div>
          </div>
        </form>

        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnHover draggable pauseOnFocusLoss />
      </div>
    </>
  );
};

export default ContactFormData;
