/* eslint-disable jsx-a11y/img-redundant-alt */
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import whoweare from "../assets/pic@2x.webp";
import {useTranslation} from "react-i18next";

const WhoWeAre = () => {
  const {t} = useTranslation("whoWeAre");
  const imageWidth = 500;
  const imageHeight = 300;
  return (
    <section className="container mx-auto px-6 sm:p-6 lg:px-12  leading-8">
      <div className="flex flex-wrap items-start py-4">
        {/* Left side with image */}
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-start mb-4 lg:mb-0">
          <div className="w-full lg:w-96 lg:px-0 px-2">
            <img src={whoweare} alt="Who We Are Image" width={imageWidth} height={imageHeight} layout="responsive" objectFit="contain" className="flex justify-center mx-auto" />
          </div>
        </div>
        {/* Right side with text content */}
        <div className="w-full lg:w-1/2">
          <h3 className="text-green-500 text-lg lg:text-xl mb-4">{t("ABOUT US")}</h3>
          <h2 className="text-3xl lg:text-5xl font-bold mb-8">{t("Who We Are?")}</h2>
          <p className="text-base lg:text-lg mb-3">{t("Welcome to HUMD, the GCC's premier print marketplace. With innovative technology, top print vendors, and a creative approach, we're unique! Explore our:")}</p>
          <ul className="list-none">
            <li className="flex items-start mb-2">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Innovation Hub:")}</span> {t("Leading digital innovation for your success.")}
              </div>
            </li>
            <li className="flex items-start mb-2">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Diverse Culture:")}</span> {t("Multilingual team catering to 10+ languages.")}
              </div>
            </li>
            <li className="flex items-start">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Creative Coalition:")}</span> {t("Prioritizing your ideas for tailored, cost-effective products.")}
              </div>
            </li>
          </ul>
          <div className="my-4">
            <p className="text-sm">
              {t("Join us at")}{" "}
              <a href="http://humd.ae/" rel="noopener noreferrer" style={{color: "green"}} target="_blank">
                HUMD
              </a>
              , {t("for a digital journey crafted with care, creativity, and cutting-edge technology")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhoWeAre;
