import React from "react";
import { useTranslation } from "react-i18next";

const Termsofservice = () => {
  const { t } = useTranslation("termsofservice");

  return (
    <div>
      <section className="body-font mb-12 pt-4">
        <div className="container lg:px-5 px-8 lg:py-10 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-4xl font-bold mb-6">{t("Terms of Service")}</h2>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-2/3 mx-auto py-3">
              <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("General")}</h2>
              <p className="mb-4 text-left text-base">
                {t("Agreeing on these Terms and Conditions does not create any partnership or relation between you and HUMD.")}<br />
                {t("If any term is considered unreasonable, it will stand void and will no longer be a part of the company’s policies.")}<br />
                {t("HUMD holds the right to assign the terms to any person. But as users, you don’t have the authority to assign these terms to any person or entity, without written consent from HUMD.")}
              </p>
            </div>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-2/3 mx-auto py-3">
              <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Conditions for Registration")}</h2>
              <p className="mb-4 text-left text-base leading-7">
                {t("HUMD allows you to register as a buyer, seller (or both) and create a user account on the website, and continue to use our services through your account.")}<br />
                {t("Users may need to furnish documents to authenticate their identity, and HUMD shall retain electronic records of such documents as required by the Law.")}<br />
                {t("HUMD holds the authority to reject the creation of a user account at its sole discretion.")}
              </p>
            </div>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-2/3 mx-auto py-3">
              <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Product Description")}</h2>
              <p className="mb-4 text-left text-base leading-7">
                {t("The product descriptions are as accurate as possible with the products listed at HUMD. Despite our best efforts, we may unintentionally fail at providing you correct or complete information, for which we have a Return Policy in place so as to minimize your inconvenience. HUMD has the authority to suspend, change, withdraw any product or service without notice.")}
              </p>
            </div>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-2/3 mx-auto py-3">
              <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Damaged Goods/Shortage")}</h2>
              <p className="mb-4 text-left text-base leading-7">
                {t("Any claims made against damage or shortages MUST be notified to HUMD within 24 hours of receiving such goods from HUMD. Make sure you check your delivery on receipt. Read our Return policy for accepting the T & C related to it.")}
              </p>
            </div>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-2/3 mx-auto py-3">
              <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Return of Goods")}</h2>
              <p className="mb-4 text-left text-base leading-7">
                {t("We try our best to please you with the products you buy from us. However, in case you receive a product which is damaged, unfit for use, or is not the good you had ordered. You can return it back to us without any tears or damages, in the same packaging as you had received.")}
              </p>
            </div>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-2/3 mx-auto py-3">
              <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Cancellation of Orders")}</h2>
              <p className="mb-4 text-left text-base leading-7">
                {t("You can cancel an order that is not yet processed by us. We will cancel the entire order and refund you the amount minus any bank charges. Orders that have been custom made and shipped will not be cancelled and you have to refer to the Return Policy related to these orders. In this matter, the customer agrees not to raise any dispute regarding any decision made by HUMD related to the cancellation process and accept the decision made by HUMD.")}
              </p>
            </div>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-2/3 mx-auto py-3">
              <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Fraudulent/Declined Transactions")}</h2>
              <p className="mb-4 text-left text-base leading-7">
                {t("HUMD has the right to recover the cost of goods, collection charges, lawyers’ fees and all legal charges, if any user has used the site for fraudulent purposes. We will also take legal action against the user using the site for fraudulent or unlawful acts.")}
              </p>
            </div>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-2/3 mx-auto py-3">
              <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Voucher Codes")}</h2>
              <p className="mb-4 text-left text-base leading-7">
                {t("HUMD reserves the right to limit the use of vouchers per person as well as the number of times a coupon can be used by a customer.")}
              </p>
            </div>
          </div>
          <div className="flex flex-col text-left w-full">
            <div className="lg:w-2/3 mx-auto py-3">
              <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Order Rejection")}</h2>
              <p className="mb-4 text-left text-base leading-7">{t("HUMD has every right to reject the following orders")}</p>
              <ol style={{ listStyleType: "disc" }} className="mx-6">
                <li className="leading-relaxed text-base mb-2">{t("Designs which go against the culture and tradition of UAE.")}</li>
                <li className="leading-relaxed text-base mb-2">{t("Designs promoting nudity, prostitution and banned substances.")}</li>
                <li className="leading-relaxed text-base mb-2">{t("Designs promoting hatred towards any race, religion, ethnicity and political affiliation/government.")}</li>
              </ol>
            </div>
            <div className="flex flex-col text-left w-full">
              <div className="lg:w-2/3 mx-auto py-3">
                <h2 className="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Disclaimer")}</h2>
                <p className="mb-4 text-left text-base leading-7">
                  {t("HUMD abides by the")} <span className="font-bold">{t("‘First Come, First Serve’")}</span> {t("policy. It is your responsibility to visit the site as soon as possible to get the products listed under discount or sale. Once the products are sold they may not be replenished by HUMD. We also do not have any policy to notify you regarding products. If disputes arise between any parties, our team will work towards reaching an amicable solution. If no amicable settlement is reached, the disputes will be settled as per UAE laws.")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Termsofservice;
