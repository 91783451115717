import React from "react";
import MainSection from "../compnents/mainSection";
import WhoWeAre from "../compnents/whoWeAre";
import Map from "../compnents/map";
import Topvendor from "../compnents/topVendor";
import Testimonial from "../compnents/testimonial";
import ResellersSection from "../compnents/resellersSection";
import VendorsSection from "../compnents/vendorsSection";
// import Kpisection from "../compnents/kpisection";
import CustomersSlider from "../compnents/customers";
import Partner from "../compnents/partner";
import Designplix from "../compnents/designplix";
import SmeSection from "../compnents/smeSection";
import EnterpriseSection from "../compnents/enterpriseSection";
import HeroSection from "../compnents/heroSection";
import ContactFormData from "../compnents/contactFormData";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <MainSection />
      <WhoWeAre />
      <SmeSection />
      <EnterpriseSection />
      <ResellersSection />
      <VendorsSection />
      <Map />
      <Topvendor />
      <Partner />
      <Designplix />
      <Testimonial />
      <CustomersSlider />
      <ContactFormData />
      {/* <Kpisection /> */}
      {/* <Newsletter /> */}
    </div>
  );
};

export default Home;
