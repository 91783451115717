/* eslint-disable jsx-a11y/img-redundant-alt */
import {Link} from "react-router-dom";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import sme from "../assets/SME-img-main-sectipn.webp";
import {useTranslation} from "react-i18next";

const SmeSection = () => {
  const {t, i18n} = useTranslation("sme");
  const imageWidth = 500;
  const imageHeight = 500;

  return (
    <section className="container mx-auto px-6 sm:p-6  lg:px-12 relative  lg:mb-0 mb-10">
      <div className={`flex flex-wrap items-start justify-center lg:${i18n.language === "en" ? "justify-start" : "justify-end"} lg:mb-12`}>
        <div className="w-full lg:w-1/2">
          <h2 className="text-3xl lg:text-5xl font-bold mb-8">{t("Startups and SMEs")}</h2>
          <p className="text-base lg:text-lg mb-3">{t("At Humd, we prioritize the success of SMEs by offering a comprehensive range of benefits:")}</p>
          <ul className="list-none">
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Design Tools:")}</span> {t("Our AI design tools streamline the creative process, reducing design time and enabling SMEs to meet their demands efficiently.")}
              </div>
            </li>
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Easy Ordering Website:")}</span> {t("User-friendly platform simplifies the ordering process, making browsing and ordering products seamless.")}
              </div>
            </li>
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Wide Range of Products:")}</span> {t("From print and promotional items to packaging products, our extensive product range ensures you find exactly what you need.")}
              </div>
            </li>
            <li className="flex items-start ">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Supplier Network:")}</span> {t("We procure top-tier products from a wide array of suppliers throughout the UAE, ensuring that you have access to products suiting your budget from standard to premium, we have it all. Our extensive network allows us to cater to your preferences effectively.")}
              </div>
            </li>
          </ul>
          <p className="py-4 ml-3">
            {t("Click here to explore how")} <span className="text-green-600">HUMD</span> {t("can help your SME thrive.")}
          </p>
          <a href="https://humd.ae/" target="_blank" rel="noreferrer">
            <button className="flex px-8 py-2 mb-6 ml-2 text-[#ED636E] text-[20px] border border-red-500 rounded-[20px] hover:bg-red-500 hover:text-white font-semibold shadow-lg box-border">
              {t("Explore")}
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 mt-2 mx-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </a>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex justify-start mx-auto lg:justify-center lg:absolute lg:top-0 lg:right-0 ">
        <div className="w-full lg:w-full lg:my-20 mx-auto flex justify-center ">
          <img src={sme} alt="sme" width={imageWidth} height={imageHeight} layout="responsive" objectFit="contain" />
        </div>
      </div>
    </section>
  );
};
export default SmeSection;
