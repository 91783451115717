/* eslint-disable jsx-a11y/img-redundant-alt */
import {Link} from "react-router-dom";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import reseller from "../assets/reseller-main-section-img.webp";
import {useTranslation} from "react-i18next";

const ResellersSection = () => {
  const {t, i18n} = useTranslation("resellers");
  const imageWidth = 500;
  const imageHeight = 500;

  return (
    <section className="container mx-auto px-6 sm:p-6 md:p-24 lg:px-12 lg:py-4 relative">
      <div className={`flex flex-wrap items-start justify-center lg:${i18n.language === "en" ? "justify-start" : "justify-end"} lg:mb-12`}>
        <div className="w-full lg:w-1/2">
          <h2 className="text-3xl lg:text-5xl font-bold mb-8">{t("Resellers")}</h2>
          <p className="text-base lg:text-lg mb-3">{t("We provide resellers with the tools and resources they need to succeed in their business:")}</p>
          <ul className="list-none">
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Better Prices:")}</span> {t("Access competitive pricing on our wide range of products to boost your margins.")}
              </div>
            </li>
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Product Range:")}</span> {t("Explore a diverse product catalog to meet the varied needs of your customers.")}
              </div>
            </li>
            <li className="flex items-start mb-4">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Relationship Manager:")}</span> {t("Enjoy dedicated support from our relationship managers, who are always ready to assist.")}
              </div>
            </li>
            <li className="flex items-start ">
              <div className="min-w-[24px]">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
              <div className="flex-1 ml-2">
                <span className="font-bold">{t("Automation Tool:")}</span> {t("Streamline your ordering and fulfillment process with our automation tools, saving you time and effort.")}
              </div>
            </li>
          </ul>
          <p className="py-4 ml-3">
            {t("Click here to discover how")} <span className="text-green-600">{t("HUMD")}</span> {t("can support your reseller business.")}
          </p>
          <Link to="/reseller">
            <button className="flex px-8 py-2 mt-4 ml-2 text-[#ED636E] text-[20px] border border-red-500 rounded-[20px] hover:bg-red-500 hover:text-white font-semibold shadow-lg box-border">
              {t("Explore")}
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 mt-2 mx-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </Link>
        </div>
      </div>

      <div className="w-full lg:w-1/2 flex justify-start mx-auto lg:justify-end lg:absolute top-10 lg:right-0 lg:-mr-12">
        <div className="w-full lg:w-full  my-10 mx-4">
          <img src={reseller} alt={t("reseller")} width={imageWidth} height={imageHeight} layout="responsive" objectFit="contain" />
        </div>
      </div>
    </section>
  );
};

export default ResellersSection;
