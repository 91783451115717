import React from "react";
import enterprisedash from "../assets/DesignEnterpriseDashbd.webp";
import ContactForm from "../compnents/contactForm";
import HowItWorksEnterprise from "../compnents/HowItWorksEnterprise";
import Mainsectionenterprise from "../compnents/mainsectionenterprise";
import Enterprisetestimonials from "../compnents/enterprisetestmonial";
import { useTranslation } from "react-i18next";
const Enterprise = () => {
  const { t } = useTranslation("enterprisecomponent");

  return (
    <div>
      <div>
        <Mainsectionenterprise />
      </div>
      <div className="lg:py-20 py-12 mx-auto px-4 lg:px-0">
        <div className="flex flex-col text-center w-full mb-6">
          <h2 className="text-3xl lg:text-5xl font-bold lg:mb-8 mb-2 text-center">{t("Who We Are?")}</h2>
          <p className="lg:w-2/3 mx-auto text-base leading-8 text-left px-4">
            {t("Calling all")} <span className="font-semibold">{t("Enterprises!")}</span> {t("Are you ready to conquer the challenges of the print, packaging, and corporate gifts market? Say hello to")} <span className="text-green-600 font-semibold">{t("HUMD")}</span>! {t("Our AI-powered platform is here to revolutionize your operations and design, helping you stand out and thrive in today's competitive landscape. With")} <span className="text-green-600 font-semibold">{t("HUMD")}</span>, {t("bid farewell to budget constraints and resource limitations – here, innovation knows no bounds!")}
          </p>
        </div>
        <div>
          <img src={enterprisedash} alt={t("enterprise dashboard")} width="800" className="mx-auto" />
        </div>
      </div>
      <HowItWorksEnterprise />
      <Enterprisetestimonials />
      <ContactForm />
    </div>
  );
};

export default Enterprise;
