import React, {useState, useEffect} from "react";
import bannerImage1 from "../assets/productimgs/002B Bottle.webp";
import bannerImage2 from "../assets/productimgs/002 Polo.webp";
import bannerImage3 from "../assets/productimgs/002 Flyer Mockup.webp";
import bannerImage4 from "../assets/productimgs/002 Gift set.webp";
import bannerImage5 from "../assets/productimgs/002B Tote Bag.webp";
import CountUp from "react-countup";
import {useTranslation} from "react-i18next";

const MainSection = () => {
  const {t, i18n} = useTranslation("main");
  const isArabic = i18n.language === "ar"; // Check if the language is Arabic

  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [bannerImage1, bannerImage2, bannerImage3, bannerImage4, bannerImage5]; // Add more images here if needed

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  return (
    <div>
      <section>
        <div className="container mx-auto flex px-5 lg:pt-12 lg:mb-10 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-full relative lg:mt-0 mt-12" style={{height: "400px"}}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt="hero"
                className={`w-full rounded-lg shadow-md border object-cover slider-image ${index === currentSlide ? "opacity-100" : "opacity-0"} ${index === currentSlide ? "visible" : "invisible"}`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  transition: "opacity 1s ease-in-out, transform 1s ease-in-out",
                  transform: index === currentSlide ? "scale(1)" : "scale(0.95)",
                }}
              />
            ))}
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pr-20 md:pr-16 md:pl-24 lg:pt-2 flex flex-col md:items-center mb-8 md:mb-0 items-center text-center mobile-margin-adjust">
            <h1 className="text-3xl font-bold lg:leading-[50px] md:text-[40px]">
              {t("Welcome to")}
              <a href="https://humd.ae/" target="_blank" rel="noreferrer">
                <span className="text-green-600 cursor-pointer mx-2">HUMD</span>
              </a>{" "}
              {t("Revolutionizing the Print, Packaging, and Corporate Gifts Industry")}
            </h1>
            <div className="flex lg:flex-row md:flex-col lg:py-8 py-4 justify-center lg:ml-12">
              <div className="flex flex-col items-center md:items-start mb-4 md:mb-0">
                <h1 className="text-2xl md:text-[35px] font-bold py-2">
                  {isArabic ? (
                    <>
                      +<CountUp end={50} duration={3} />
                    </>
                  ) : (
                    <>
                      <CountUp end={50} duration={3} />+
                    </>
                  )}
                </h1>
                <h6 className="text-gray-900 text-left">{t("Vendors")}</h6>
              </div>
              <div className="mx-4 md:mx-10 flex flex-col items-center md:items-start mb-4 md:mb-0">
                <h1 className="text-2xl md:text-[35px] font-bold py-2">
                  {isArabic ? (
                    <>
                      +<CountUp end={10000} duration={3} />
                    </>
                  ) : (
                    <>
                      <CountUp end={10000} duration={3} />+
                    </>
                  )}
                </h1>
                <h6 className="text-gray-900">{t("Total Products")}</h6>
              </div>
              <div className="flex flex-col items-center md:items-start ml-2">
                <h1 className="text-2xl md:text-[35px] font-bold py-2">
                  {isArabic ? (
                    <>
                      +<CountUp end={2000000} duration={3} />
                    </>
                  ) : (
                    <>
                      <CountUp end={2000000} duration={3} />+
                    </>
                  )}
                </h1>
                <h6 className="text-gray-900 text">{t("Products Delivered")}</h6>
              </div>
            </div>
            );
            <a href="https://humd.ae/" target="_blank" rel="noreferrer">
              <button className="flex px-8 py-2 lg:mt-2 text-[#ED636E] text-[20px] border border-red-500 rounded-[20px] hover:bg-red-500 hover:text-white font-semibold shadow-lg box-border">
                {t("Explore Our Store")}
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 mt-2 mx-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainSection;
