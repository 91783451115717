import React from "react";
import resellerdash from "../assets/DesignReseller-dashbd.webp";
import ContactForm from "../compnents/contactForm";
import HowItWorksResellers from "../compnents/howItWorksResellers";
import Mainsectionresellers from "../compnents/mainsectionresellers";
import {useTranslation} from "react-i18next";
import Resellertestimonial from "../compnents/resellertestimonial";

const Reseller = () => {
  const {t} = useTranslation("resellercomponent");
  return (
    <div>
      <Mainsectionresellers />
      <div className="lg:py-20 py-12 mx-auto">
        <div className="flex flex-col text-center w-full mb-6">
          <h2 className="text-3xl lg:text-5xl font-bold lg:mb-8 mb-2 text-center">{t("Who We Are?")}</h2>
          <p className="lg:w-2/3 mx-auto text-base leading-8 text-left px-6">
           {t(" At")} <span className="text-green-600 font-semibold">HUMD</span>, {t("we understand the dynamic landscape resellers navigate a world where margins are tight, and customer satisfaction is paramount. Our platform is tailor-made for this environment, offering a comprehensive suite of tools and resources crafted to empower resellers like you to thrive in this competitive arena. From enhancing margins to optimizing operations and ensuring top-notch customer service,")} <span className="text-green-600 font-semibold">HUMD</span> {t("equips you with the solutions you need to scale your business, increase profits, and exceed customer expectations.")}
          </p>
        </div>
        <div>
          <img src={resellerdash} alt="" width="800" className="mx-auto " />
        </div>
      </div>
      <HowItWorksResellers />
      <Resellertestimonial />
      <ContactForm />
    </div>
  );
};

export default Reseller;
