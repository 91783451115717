import React, {useEffect, Suspense} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import Home from "./pages/Home";
import Reseller from "./pages/Reseller";
import Vendor from "./pages/Vendor";
import Header from "./compnents/header";
import Footer from "./compnents/footer";
import Enterprise from "./pages/Enterprise";
import Blog from "./pages/Blog";
import Job from "./pages/Job";
import Contactus from "./pages/Contactus";
import Sustainable from "./pages/Sustainable";
import About from "./pages/about";
import Culture from "./pages/culture";
// Import other pages as needed
import {useTranslation} from "react-i18next";
import Returns from "./pages/returns";
import Termsofservice from "./pages/termsofservice";
import Privacypolicy from "./pages/privacypolicy";

const App = () => {
  const {i18n} = useTranslation();

  useEffect(() => {
    document.documentElement.setAttribute("dir", i18n.language === "ar" ? "rtl" : "ltr");
  }, [i18n.language]);

  return (
    <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <Suspense fallback={<div></div>}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/vendors" element={<Vendor />} />
            <Route path="/enterprise" element={<Enterprise />} />
            <Route path="/reseller" element={<Reseller />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/job" element={<Job />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/culture" element={<Culture />} />
            <Route path="/suntainablity" element={<Sustainable />} />
            <Route path="/returns" element={<Returns />} />
            <Route path="/terms-of-service" element={<Termsofservice />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
          </Routes>
          <Footer />
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
