import React from "react";
import map from "../assets/uaemap.webp";
import map1 from "../assets/HUMD-facts.webp";
import { useTranslation } from "react-i18next";

const Map = () => {
  const { t, i18n } = useTranslation("map");
  const imageWidth = 650;
  const imageHeight = 640;

  return (
    <>
      <div className="bg-[#111827] ">
        <h2 className={`text-3xl lg:${i18n.language === "en" ? "text-5xl" : "text-3xl"} font-bold pt-6 text-white text-center lg:mx-0 mx-4`}>
          {t("Our Trusted Vendors All Over the GCC")}
        </h2>

        <div>
          <img src={map} alt={t("reseller")} width={imageWidth} height={imageHeight} layout="responsive" objectFit="contain" className="block lg:mx-auto pt-20 pb-2" />
        </div>
      </div>

      <div className="bg-[#ED636E] py-8">
        <h1 className="text-white font-bold text-center text-4xl">{t("HUMD Facts")}</h1>
        <img src={map1} alt={t("reseller")} width="700px" layout="responsive" objectFit="contain" className="block mx-auto w-2/3" />
      </div>
    </>
  );
};

export default Map;
