import React from "react";
import { useTranslation } from "react-i18next";
import img1 from "../assets/HUMD ICONS-01.webp";
import img3 from "../assets/HUMD ICONS-03.webp";
import img4 from "../assets/HUMD ICONS-04.webp";
import img5 from "../assets/Humd__Onboarding.png";
import img6 from "../assets/humd.png";

const resellerdetailss = [
  {
    name: "Sign Up",
    desc: "You can sign up in just one minute and expect to be contacted within 24 hours.",
    image: img1,
  },
  {
    name: "On Boarding",
    desc: "Quickly upload products and data, analyze spec's, and review attractiveness.",
    image: img6,
  },
  {
    name: "Agreement",
    desc: "Keep it short and simple with transparent conditions. Sign up online effortlessly.",
    image: img5,
  },
  {
    name: "Testing",
    desc: "Efficiently test and manage orders with humd. Optimize and prepare for launch!",
    image: img3,
  },
  {
    name: "Launch",
    desc: "Get real orders, sell your product, and develop with humd.",
    image: img4,
  },
];

const HowItWorksVendors = () => {
  const { t } = useTranslation("vendorcomponent");
  return (
    <div>
      <section>
        <div className="container px-5 lg:py-4 mx-auto">
          <div className="flex flex-col text-center w-full mb-10">
            <h2 className="text-3xl lg:text-5xl font-bold">{t("How it works?")}</h2>
          </div>
          <div className="flex flex-wrap justify-center">
            {resellerdetailss.map((details, index) => (
              <div className="p-4 md:w-1/3 lg:w-1/5" key={index}>
                <div className="h-full rounded-lg hover:shadow-lg transition-shadow duration-300">
                  <div className="p-6 flex flex-col items-center text-center">
                    <img alt={t("process step")} className="w-20 h-20 rounded-full object-cover object-center mb-4" src={details.image} />
                    <h2 className="text-xl font-bold text-gray-900 mb-4">{t(details.name)}</h2>
                    <p className="text-gray-700 text-center">{t(details.desc)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowItWorksVendors;
