/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link } from "react-router-dom";
import desginimg from "../assets/Design Toolkit.webp";
import { useTranslation } from "react-i18next";

const Designplix = () => {
  const { t, i18n } = useTranslation("designplix");
  const imageWidth = 500;
  const imageHeight = 500;

  return (
    <section className="container mx-auto px-6 lg:px-8 relative">
      <div className={`flex flex-wrap items-start justify-center lg:${i18n.language === "en" ? "justify-start" : "justify-end"} lg:mb-24 mb-4`}>
        <div className="w-full lg:w-1/2">
          <h2 className="text-3xl lg:text-5xl font-bold my-8">{t('Create designs with our AI-powered Design studio!')}</h2>
          <p className="text-base lg:text-lg mb-3">{t('Whip up stunning business cards, letterheads, brochures, QR Code and more with a dash of magic and a sprinkle of fun. Our smart templates and easy-to-use tools make it a breeze to personalize your creations. Add a touch of you with your logo, photos, and words. Download your designs ready for print or share them online with a click. Watch your ideas come to life and leave a lasting impression with every design you craft!')}</p>
          <Link to="/contactus">
            <button className="flex px-8 py-2 my-6 text-[#ED636E] text-[20px] border border-red-500 rounded-[20px] hover:bg-red-500 hover:text-white font-semibold shadow-lg box-border">
              {t('Contact us')}
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 mt-2 mx-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </Link>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex justify-start mx-auto lg:justify-end lg:absolute lg:top-10 lg:right-0 lg:-mr-12">
        <div className="w-full lg:w-full lg:mt-4 lg:my-4">
          <img src={desginimg} alt={t("design image")} width={imageWidth} height={imageHeight} layout="responsive" objectFit="contain" />
        </div>
      </div>
    </section>
  );
};

export default Designplix;
