import React from "react";
import { useTranslation } from "react-i18next";
import img21 from "../assets/Exceptional Partners-03 (1).webp";
import img22 from "../assets/Exceptional Partners-02 (1).webp";
import img23 from "../assets/Exceptional Partners-04 (1).webp";
import img24 from "../assets/Exceptional Partners-05 (1).webp";
import img25 from "../assets/Showcase logo-06.png";
import img26 from "../assets/Showcase logo-07 (1).png";
import img27 from "../assets/Showcase logo-08.png";
import img28 from "../assets/Showcase logo-09.png";

const Partner = () => {
  const { t } = useTranslation("partner");

  return (
    <section className="flex flex-wrap justify-center px-2 mx-auto lg:py-10">
      <section className="text-black body-font lg:w-1/2 mx-auto w-full">
        <div className="container px-5 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-3xl lg:text-5xl font-bold lg:mb-12 text-center">{t("Product Showcase")}</h2>
            <div className="flex mx-auto">
              <div>
                <img src={img25} className="w-48 h-48" alt="" />
                <img src={img26} className="w-48 h-48" alt="" />
              </div>
              <div>
                <img src={img27} className="w-48 h-48" alt="" />
                <img src={img28} className="w-48 h-48" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-black body-font lg:w-1/2 mx-auto w-full">
        <div className="container px-5 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-3xl lg:text-5xl font-bold lg:mb-12 text-center">{t("Exceptional Partners")}</h2>
            <div className="flex mx-auto">
              <div>
                <img src={img21} className="w-48 h-48" alt="" />
                <img src={img22} className="w-48 h-48" alt="" />
              </div>
              <div>
                <img src={img23} className="w-48 h-48" alt="" />
                <img src={img24} className="w-48 h-48" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Partner;
