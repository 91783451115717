import React from "react";
import { useTranslation } from "react-i18next";

const Job = () => {
  const { t } = useTranslation("jobcomponent");

  return (
    <div>
      <section className="body-font mb-12 pt-4">
        <div className="container px-5 lg:py-10 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-4xl font-bold mb-6">
              {t("Join the HUMD Revolution: Careers at")} <span className="text-green-600"> HUMD</span>
            </h2>
            <p className="lg:w-1/2 mx-auto leading-relaxed text-base mb-4 text-left lg:text-center">
              {t("Are you passionate about printing and ready to make an impact? Look no further! HUMD is the GCC's largest printing marketplace, and we're always on the lookout for talented individuals to join our dynamic team.")}
            </p>
          </div>
          <div className="flex flex-wrap lg:w-2/3 sm:mx-auto sm:mb-2 -mx-2">
            <p className="lg:text-center text-left m-4">{t("At HUMD, we offer a range of exciting career opportunities in areas such as:")}</p>
            {[
              t("Printing Operations"),
              t("Customer Service"),
              t("Marketing"),
              t("Sales"),
              t("Graphic Design"),
              t("Technology and Development")
            ].map((area, index) => (
              <div className="p-2 sm:w-1/2 w-full" key={index}>
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">{area}</span>
                </div>
              </div>
            ))}
            <p className="text-left mx-4 my-2">
              {t("Whether you're a seasoned professional or just starting your career journey,")} <span className="text-green-600">HUMD</span> {t("provides a collaborative and innovative work environment where your ideas are valued, and your growth is nurtured")}
            </p>
          </div>
        </div>
        <div className="flex flex-col text-center w-full lg:mb-20">
          <h2 className="text-4xl font-bold mb-6 text-center">{t("Future Openings")}</h2>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-left lg:px-12 px-8">
            {t("Currently, we don't have any specific job openings, but we're always interested in hearing from talented individuals who share our passion for printing and innovation. Feel free to submit your CV to info@humd.co for future reference. Join us in our mission to revolutionize the printing industry and explore beyond the print shop with HUMD. We look forward to welcoming you to our team!")}
          </p>
        </div>
      </section>
    </div>
  );
};

export default Job;
