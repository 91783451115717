import React from "react";
import imgs2 from "../assets/newsletter.webp";
import { useTranslation } from 'react-i18next';

const Newsletter = () => {
  const { t } = useTranslation('newsletter');
  return (
    <div>
      <section className="text-white bg-green-700">
        <div className="container mx-auto flex px-5 md:flex-row flex-col items-center pt-4 lg:pt-0">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4">{t('Subscribe to Our Newsletter')}</h1>
            <p className="mb-8 leading-relaxed">{t('Get the latest insights from Humd straight to your inbox, with a commitment to privacy and an easy opt-out.')}</p>
            <div className="flex w-full md:justify-start justify-center items-end">
              <div className="relative mr-4 md:w-full lg:w-full xl:w-1/2 w-2/4">
                <input type="text" id="hero-field" name="hero-field" className="w-full bg-white rounded border border-gray-300 focus:ring-2 focus:ring-green-200 focus:bg-transparent focus:border-green-500 text-base outline-none py-2 px-4 leading-8 transition-colors duration-200 ease-in-out" placeholder="abc@humd.com" />
              </div>
              <button className="inline-flex text-green-600 bg-white border-0 py-2 px-6 focus:outline-none hover:bg-green-600 hover:text-white rounded text-lg font-semibold leading-8">{t('Subscribe')}</button>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img className="object-cover object-center rounded" alt="hero" src={imgs2} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Newsletter;
