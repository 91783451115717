/* eslint-disable jsx-a11y/img-redundant-alt */
import topvendor from "../assets/API-01.webp";
import { useTranslation } from "react-i18next";

const Topvendor = () => {
  const { t, i18n } = useTranslation("topvendor");
  const imageHeight = 700;

  return (
    <section className="container mx-auto sm:p-6 lg:px-24 lg:py-10 p-12">
      <h2 className="text-3xl lg:text-5xl font-bold lg:my-10">{t('Tech Stack & APIs')}</h2>
      <div className="flex flex-wrap items-start justify-center lg:justify-start">
        <div className="w-full lg:w-1/2 py-4">
          <p className="text-base lg:text-lg mb-3">{t('Humd uses a robust tech stack and APIs to deliver a seamless user experience and powerful functionality. Learn more about the technologies powering our platform here.')}</p>
          
          <form action="">
            <div className="relative mb-4">
              <label htmlFor="email" className="leading-7 text-sm ">
                {t("Enter Name:")}
              </label>
              <input type="name" id="email" name="email" className="w-full bg-white rounded border border-gray-400 focus:border-green-500 focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            </div>
            <div className="relative mb-4">
              <label htmlFor="number" className="leading-7 text-sm ">
                {t("Enter Contact No:")}
              </label>
              <input type="number" id="number" name="number" className="w-full bg-white rounded border border-gray-400 focus:border-green-500 focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            </div>

            <button className="hover:bg-[#ED636E] hover:text-white text-[#ED636E] text-[20px] border border-[#ED636E] rounded-[20px] border-1 py-3 px-6 focus:outline-none text-sm font-medium">{t("Request a Demo")}</button>
          </form>
        </div>
        <div className="w-full lg:w-1/2 flex justify-center mt-4 mx-auto lg:mb-0">
          <div className="w-full lg:w-full mx-auto flex">
            <img src={topvendor} alt={t("reseller")} height={imageHeight} className="lg:mx-12 flex justify-center mx-auto" layout="responsive" objectFit="contain" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Topvendor;
