import React, {Suspense} from "react";
import vendordash from "../assets/DesignVendor dashbd.webp";
import ContactForm from "../compnents/contactForm";
import HowItWorksVendors from "../compnents/HowItWorksVendors";
import Mainsectionvendors from "../compnents/mainsectionvendors";
import {useTranslation} from "react-i18next";
import Vendortestimonial from "../compnents/vendorTestimonil";
const Vendor = () => {
  const {t} = useTranslation("vendorcomponent");
  return (
    <div>
      <Suspense fallback={<div></div>}>
        <div>
          <Mainsectionvendors />
        </div>
        <div className="lg:py-20 py-12 mx-auto px-4 lg:px-0">
          <div className="flex flex-col text-center w-full mb-6">
            <h2 className="text-3xl lg:text-5xl font-bold lg:mb-8 mb-2 text-center">{t("Who We Are?")}</h2>
            <p className="lg:w-2/3 mx-auto text-base leading-8 text-left p-4">{t("Attention vendors in the print, packaging, and corporate gifts industry! Tired of navigating a complex market? Say hello to Humd! Our comprehensive platform is designed to tackle your biggest challenges head-on, from inventory management to pricing and distribution. With Humd, unlock endless opportunities for growth and reach new customers effortlessly.")}</p>
            <div>
              <img src={vendordash} alt={t("vendor dashboard")} width="800" className="mx-auto" />
            </div>
          </div>
        </div>
        <HowItWorksVendors />
        <Vendortestimonial />
        <ContactForm />
      </Suspense>
    </div>
  );
};

export default Vendor;
