import React from "react";
import {useTranslation} from "react-i18next";

const HeroSection = () => {
  const {t, i18n} = useTranslation("herosection");
  const isArabic = i18n.language === "ar";

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className={`animation lg:text-xl  px-2 ml-2 lg:ml-0 text-center font-bold text-uppercase ${isArabic ? "lg:mr-44 lg:ml-0" : "lg:ml-36"}`}>
        <span className="text-shadow lg:tracking-[3px]">{t("LAUNCHING")} </span>
        <span className="lg:tracking-[3px]">{t("SOON IN")}</span>
        <span className="mx-2 text-green-600 font-extrabold tracking-[3px]">{t("Saudi Arabia")}!</span>
      </h1>
    </div>
  );
};

export default HeroSection;
