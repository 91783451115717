import React from "react";
import { useTranslation } from "react-i18next";

const Privacypolicy = () => {
  const { t } = useTranslation("privacyPolicy");

  return (
    <div>
      <section class="body-font mb-12 pt-4">
        <div class="container lg:px-5 px-8 lg:py-10 mx-auto">
          <div class="flex flex-col text-center w-full ">
            <h2 className="text-4xl font-bold mb-6 ">{t("HUMD Privacy Policy.")}</h2>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base mb-4 text-left">{t("This Privacy Policy explains how HUMD collects, uses, and discloses information related to the products and services we provide.")}</p>
          </div>
          <div class="flex flex-col text-left w-full ">
            <div class="lg:w-2/3 mx-auto py-3 ">
              <h2 class="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Changes to the Privacy Policy")}</h2>
              <p class="mb-4 text-left text-base">{t("HUMD reserves the right to update this Privacy Policy at any time. We will post any changes here, and the updated policy will be effective immediately. We do not need to notify you of the changes, so it's your responsibility to check for updates regularly.")}</p>
            </div>
          </div>
          <div class="flex flex-col text-left w-full ">
            <div class="lg:w-2/3 mx-auto py-3 ">
              <h2 class="mx-auto text-3xl font-bold mb-4 text-gray-700">{t("Your Right to Know")}</h2>
              <p class="mb-4 text-left text-base">{t("You have the right to understand how your information is used and shared. We take various security measures to protect your information. By visiting humd.co, you agree to the terms below. If you have questions about how we use your personal information, please contact us at hello@humd.co.")}</p>
            </div>
          </div>
          <div class="flex flex-col text-center w-full ">
            <div class="lg:w-2/3 lg:mx-auto mb-6 text-left ">
              <h2 class="text-gray-700 mb-4 text-3xl font-bold">{t("Information We Collect")}</h2>
              <p class="mb-4 text-left text-base">{t("We collect personal information from you in several ways:")}</p>
              <p class="text-xl mb-4 text-left font-bold">{t("Information You Provide")}</p>
              <ol style={{ listStyleType: "disc" }} className="mx-4">
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Buyer/Seller Registration:")}</span> {t("Name, address, phone number, email address.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Verification:")}</span> {t("Emirates ID, trade license, VAT registration, power of attorney (if applicable), business information (company name, type, industry).")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Sellers:")}</span> {t("Information about products you sell and your transactions.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Buyers (Bank Transfer/Escrow):")}</span> {t("Credit/debit card details, bank account number.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Buyers & Sellers:")}</span> {t("Information about each transaction (products purchased, price).")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Public Information:")}</span> {t("Seller reviews and other information submitted for publication.")}
                </li>
              </ol>
              <p class="text-xl my-6 text-left font-bold">{t("Additional Information")}</p>
              <ol style={{ listStyleType: "disc" }} className="mx-4">
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("User Activity:")}</span> {t("Delivery information, disputes, complaints, user communications, forum discussions.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Events:")}</span> {t("Contact information (name, address, phone, fax, email) from trade shows and industry events.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Device Information:")}</span> {t("IP address, device type, unique device ID, browser type, location (country/city), browsing patterns, how you found HUMD.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Website Usage:")}</span> {t("Browser software, operating system, hardware/software attributes, pages viewed, sessions, unique visitors.")}
                </li>
              </ol>
              <p class="my-4 text-left text-base">{t("We use some of this information with cookies to improve HUMD and understand our visitors.")}</p>
            </div>
          </div>
          <div class="flex flex-col text-center w-full ">
            <div class="lg:w-2/3 lg:mx-auto text-2xl mb-6 text-left ">
              <h2 class="text-gray-700 mb-4 text-3xl font-bold">{t("Use of Personal Information")}</h2>
              <p class="mb-4 text-left text-base">{t("HUMD respects your privacy. We will not sell or share your information or order details with third parties without your consent, unless required by law. We collect and use your information for the following purposes:")}</p>
              <ol style={{ listStyleType: "disc" }} className="mx-4">
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Security:")}</span> {t("Verifying your identity, conducting security checks, and setting up user accounts.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Eligibility:")}</span> {t("Confirming your eligibility to register under our User Terms & Conditions.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Account Setup & Management:")}</span> {t("Creating and managing your user account.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Customer Service & Transactions:")}</span> {t("Providing customer support, processing transactions, and settling payments between buyers and sellers.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Logistics & Warehousing:")}</span> {t("Delivering logistics and warehousing services.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Account Security Monitoring:")}</span> {t("Ensuring the security of your account.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Website Improvement:")}</span> {t("Research and analysis to improve HUMD's content and layout.")}
                </li>
              </ol>
              <p class="my-4 text-left text-base">{t("We will never disclose your username, password, email address, or other security details. As a Buyer, we may share details of your recently viewed products to allow sellers to contact you with relevant offers. We may use your information for other purposes permitted by privacy laws.")}</p>
            </div>
          </div>
          <div class="flex flex-col text-center w-full ">
            <div class="lg:w-2/3 lg:mx-auto text-2xl mb-6 text-left ">
              <h2 class="text-gray-700 mb-4 text-3xl font-bold">{t("Disclosure of Personal Information")}</h2>
              <p class="mb-4 text-left text-base">{t("We may disclose your information to the following:")}</p>
              <ol style={{ listStyleType: "disc" }} className="mx-4">
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Professional Advisors, Authorities & Law Enforcement:")}</span> {t("To comply with laws, exercise legal rights, or defend HUMD from legal claims.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Potential Buyers:")}</span> {t("In connection with a potential purchase, merger, or acquisition of HUMD.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Other HUMD Users:")}</span> {t("When necessary to facilitate purchases and communications between users.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Third-Party Partners:")}</span> {t("Service providers or affiliates that help us provide services or process your information.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Content Owners:")}</span> {t("To investigate intellectual property infringement claims.")}
                </li>
                <li class="leading-relaxed text-base mb-2 ">
                  <span className="font-bold">{t("Website & Service Providers:")}</span> {t("Companies that help maintain and improve HUMD. These include:")}
                  <ol className="px-4">
                    <li>- {t("Payment gateway providers")}</li>
                    <li>- {t("Banks")}</li>
                    <li>- {t("Supply chain finance partners")}</li>
                    <li>- {t("Logistics partners")}</li>
                    <li>- {t("Custom agents")}</li>
                    <li>- {t("Customer service providers")}</li>
                    <li>- {t("Business partners")}</li>
                    <li>- {t("Marketing and advertising platforms (Google, Twitter, Facebook, Instagram)")}</li>
                  </ol>
                </li>
              </ol>
              <p class="my-4 text-left text-base">{t("HUMD will not disclose buyer or seller information for any reason other than those listed above.")}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacypolicy;
