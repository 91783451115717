import React from "react";
import img1 from "../assets/vendor-main-section-img.webp";
import { useTranslation } from "react-i18next";

const Mainsectionvendors = () => {
  const { t } = useTranslation("vendorcomponent");
  return (
    <div>
      <section className="text-gray-600 body-font bg-[#F3F4F6] lg:h-[450px] lg:mx-24">
        <div className="container mx-auto flex lg:px-5 px-2 py-12 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col lg:mb-16 mb-8 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl font-medium text-gray-900 lg:mb-10 text-center">
              {t("Unleash Your Potential and Expand Your Reach with HUMD")}
            </h1>
            <a href="https://humd.ae/" target="_blank" rel="noreferrer">
              <button className="flex text-white bg-[#ED636E] border-0 py-2 px-6 mt-4 lg:mt-0 rounded-lg focus:outline-none justify-center mx-auto">
                {t("Explore")}
              </button>
            </a>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img className="object-cover object-center rounded lg:w-96" alt={t("hero")} src={img1} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Mainsectionvendors;
