import React from "react";
import { useTranslation } from "react-i18next";
import flag2 from "../assets/uaeflag.webp";

const testimonials = [
  {
    name: "Zabella Demirchian",
    position: "HOGI Marketing Agency",
    rating: 5,
    img: flag2,
    content: "Working with Humd has been an absolute pleasure. Their dedication to delivering high-quality printed materials, consistently on time, has exceeded our expectations. Their unwavering support and prompt responses to our inquiries have made them a trusted partner for our marketing needs. Humd's commitment to excellence has greatly contributed to the success of our projects. Highly recommended",
  },
  {
    position: "TECH5",
    rating: 5,
    img: flag2,
    content: "HUMD has been our trusted partner for over two years, and we're delighted with their service. The team is consistently responsive and helpful, addressing any queries or concerns promptly. Their extensive and well-organized product catalog makes finding what we need a breeze. Highly recommended!",
  },
  {
    name: "Ruby Ramos",
    position: "Manzil Home Health Services LLC",
    rating: 4,
    img: flag2,
    content: "The ordering process is incredibly convenient since transactions are conducted online, and deliveries are always on time. Thumbs up to Humd Team!",
  },
];

const Testimonial = () => {
  const { t } = useTranslation("testimonial");

  return (
    <div>
      <section>
        <div className="container px-4 py-12 lg:mb-8 mx-auto">
          <div className="flex flex-col text-center w-full mb-10">
            <h2 className="text-3xl lg:text-5xl font-bold lg:mb-10 text-center text-black">
              {t("What People Think About Us")}
            </h2>
          </div>
          <div className="flex flex-wrap lg:flex-nowrap justify-center -mx-2">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="w-full lg:w-1/3 mb-6 p-4 shadow-lg border bg-slate-50 mx-2">
                <div className="h-full text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="inline-block w-6 h-6 text-gray-400 my-4"
                    viewBox="0 0 975.036 975.036"
                  >
                    <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                  </svg>
                  <p className="leading-relaxed text-left lg:text-center lg:h-60">"{t(testimonial.content)}"</p>
                  <span className="inline-block h-1 w-10 rounded bg-green-500 mt-6 mb-4"></span>
                  <p className="text-gray-500">{t(testimonial.position)}</p>
                  {/* rating */}
                  <div className="flex mx-auto justify-center pt-2 ">
                    <div className="flex mx-auto justify-center pt-2 ">
                      <img src={testimonial.img} className="w-8" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
